import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import ContentTag from 'src/components/functional/content-tag';
import Page from 'src/components/functional/page';
import {useInformation} from 'src/functionality/information-provider';
import {ListGroup, Container} from 'react-bootstrap';
import Markdown from 'react-markdown';
import styled from 'styled-components';
import background from 'src/images/placeholder.jpg';
import {useLocation} from '@reach/router';
import {usePageContent} from 'src/functionality/content-provider';
import {theme} from 'styles/theme';
import {Link} from 'gatsby';

const Menu = styled(ListGroup)`

`;

const Option = styled.button`
  /* margin: auto; */
  &:hover{
    filter: brightness(95%);
    cursor: pointer;
  }
  width: 100%;
  margin: 0.1em auto;
  padding: 0.5em 0;
  background-color: ${theme.bodyBg};
  transition: 0.15s ease-in-out;
`;

const BackOption = styled(Option)`
  font-weight: bold;
  margin-top: 0.75em;
`;

const MenuContainer = styled(Container)`
  height: calc(100vh - 6vh);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  & > div{
    width: 100%;
    padding: 1.25em;
    background-color: ${theme.light};
    border-radius: 3px;
    max-width: 50em;
    min-width: 300px;
    padding-top: 2.5em;
  }
`;

const MenuFooter = styled.div`
  border-top: 1px solid ${theme.mediumGray};
  margin-top: 0.5em;
  padding-top: 0.5em;
  display: flex;
  justify-content: space-between;
`;

const MenuFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 4em;
  margin-top: 1.5em;
`;

const DetailedInformationContainer = styled(Container)`
  padding-top: 5em;
`;

const BackgroundWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${background});
  background-position: middle middle;
  background-size: cover;
`;

const InformationExplorerPage = ({pageContext}) => {
  const [informationTree, informationList] = useInformation();
  const [informationHistory, setInformationHistory] = useState();
  const [viewDetailedInformation, setViewDetailedInformation] = useState(false);
  const [information, setInformation] = useState();

  const location = useLocation();
  const content = usePageContent(location.pathname, pageContext.content);

  useEffect(() => {
    if (informationTree && informationList) {
      const urlHash = window.location.hash.substring(1);
      const urlInformation = informationList.find((information) =>
        information.slug === urlHash);

      if (urlInformation) {
        setInformationHistory([urlInformation]);
        setViewDetailedInformation(true);
      } else {
      // If the information tree has been set
        setInformationHistory([informationTree]);
      }
    }
  }, [informationTree, informationList]);

  useEffect(() => {
    if (informationHistory) {
      setInformation(informationHistory[informationHistory.length - 1]);
    }
  }, [informationHistory]);

  const selectOption = (option) => {
    setInformationHistory((arr) => [...arr, option]);

    if (option.options.length === 0) { // If it's the last option, view details
      // Navigate to details
      window.location = `#${option.slug}`;
      setViewDetailedInformation(true);
    }
  };

  const previousOption = () => {
    // If we were just viewing detailed information:
    if (viewDetailedInformation) {
      window.location = '#';
      setViewDetailedInformation(false);
    }
    // Check that it isn't the first option
    if (informationHistory.length > 1) {
      setInformationHistory((arr) => arr.slice(0, -1));
    }
  };

  return (
    <Page>
      {!viewDetailedInformation && information &&
        <BackgroundWrapper>
          <MenuContainer>
            <div className="shadow-lg">
              {content &&
                <div>
                  <h2>
                    {content.IE01.title}
                  </h2>
                  <Markdown>
                    {content.IE01.body}
                  </Markdown>
                </div>
              }
              <Menu variant="flush">
                {information.options.map((option, key) => {
                  return (
                    <Option
                      key={key}
                      onClick={(e) => selectOption(option)}
                      className="plain-button"
                    >
                      {option.label && option.label}
                    </Option>
                  );
                })
                }
                {informationHistory.length > 1 &&
                <BackOption
                  className="plain-button"
                  onClick={() => previousOption()}
                >
                  &lt; Back
                </BackOption>
                }
              </Menu>
              <MenuFooterWrapper>
                <small className="text-muted">
                  arbias only uses these responses to provide information
                  relevant to you and do not store any of your answers.
                </small>
                <MenuFooter>
                  <div>
                    <Link to="/information/">&lt;Back to information home</Link>
                  </div>
                  <div>
                    <Link to="/legal">Privacy Policy</Link>
                  </div>
                </MenuFooter>
              </MenuFooterWrapper>
            </div>
          </MenuContainer>
        </BackgroundWrapper>
      }
      {viewDetailedInformation && information &&
        <DetailedInformationContainer>
          <button
            className="plain-button"
            onClick={() => previousOption()}
          >
            &lt; Back
          </button>
          {information.Content.items.map((Content, key) => {
            return (
              <div key={key}>
                <ContentTag>{Content.contentTag}</ContentTag>
                <h3>{Content.title}</h3>
                <Markdown>{Content.body}</Markdown>
              </div>
            );
          })}
        </DetailedInformationContainer>
      }
      {/* <h1>Information</h1>
      <Row>
        <Col>
          {information &&
          <>
            <div>
              <h4>Information #{information.index}</h4>
              <button onClick={() => previousOption()}>&lt; Back</button>
              {information.Content &&
                <div>
                  <ContentTag>
                    {information.Content.contentTag}
                  </ContentTag>
                  <em>{information.Content.title}</em>
                  <p>{information.Content.title}</p>
                </div>
              }
            </div>
            <ul>
              {information.options.map((option, key) => {
                return (
                  <li key={key}>
                    <button onClick={(e) => selectOption(option)}>
                      {option.label && option.label}
                    </button>
                  </li>
                );
              })}
            </ul>
          </>
          }
        </Col>
        <Col>
          {information &&
            informationHistory.map((info, key) => {
              return (
                <div key={key}>
                  <em>{info.label && info.label}</em>
                  {info.Content &&
                    <div>
                      <h3>{info.Content.title}</h3>
                      <Markdown>
                        {info.Content.body}
                      </Markdown>
                    </div>
                  }
                </div>
              );
            })}
        </Col>
      </Row> */}
    </Page>
  );
};

InformationExplorerPage.propTypes = {
  pageContext: PropTypes.shape({
    content: PropTypes.object,
  }),
};

export default InformationExplorerPage;


