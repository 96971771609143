import {useEffect, useState} from 'react';
import {API} from 'aws-amplify';
import {listInformationTargets} from 'src/graphql/content';

/**
 * Reconstructs a dictionary of index: {...attributes, options[index]} to a tree
 * This is a recursive function which begins at the root and works its way
 * through all child options
 * @param {object} informationsArray a dictionary-style object with multiple
 * informations[index: {...}, index:{...}, ...]
 * @param {object} root the root of the tree
 * @return {object} a tree of information{
 *   label, the label which corrosponds to the option selected
 *   content, (the content associated with the answer)
 *   options{...} (the options to the current element's question)
 * }
 */
const reconstruct = (informationsArray, root) => {
  if (!informationsArray) {
    return null;
  }

  delete root.undefined;
  // Reconstruct and populate each element's children options (by index)
  root.options = root.options.map((index) => {
    return (reconstruct(
        informationsArray,
        informationsArray[index]));
  });

  return root;
};


// TODO: Verify Below
/**
 * Returns the informations
 * @return {object} an object with multiple informations
 */
export function useInformation() {
  const [information, setInformation] = useState({
    tree: undefined,
    list: undefined,
  });

  useEffect(() => {
    if (!information.tree || !information.list) {
      (async () => {
        const data = await API.graphql(
            {
              query: listInformationTargets,
            });
        const infoArray = data.data.listInformationTargets.items.reduce(
            (map, obj) => {
              map[obj.index] = obj;
              return map;
            }, {});

        const infoTree = reconstruct(
            infoArray,
            infoArray[0], // element with index = 0 is the root
        );
        setInformation({tree: infoTree, list: Object.values(infoArray)});
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [information.tree, information.list];
}
